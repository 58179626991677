export const web = {
  apiUrl: 'https://v4yu3lt9z0.execute-api.us-east-1.amazonaws.com/v1',
  baseUrl: ''
}

export const aws = {
  Auth: {
    region: 'us-east-1',
    userPoolWebClientId: '1ieqckj3mhe128010e61eq6icg',
    userPoolId: 'us-east-1_Ez8ufbfNG'
  }
}
