<script>
export default {
}
</script>

<template>
  <div>
    &copy; 2020 Corey Beres.
    Source available on <a href="https://github.com/cberes/gardens">GitHub</a>.
  </div>
</template>

<style scoped>
</style>
